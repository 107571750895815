<template>
  <el-dialog title="修改" :visible="visible" @close="close">
    <el-form :model="sform" ref="form" :rules="rules" label-width="100px">
      <el-form-item label="考勤类型：" prop="status">
        <el-radio-group v-model="sform.status">
          <el-radio v-for="(v, k) in types" :key="k" :label="k + 1">{{
            v
          }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="请假类型：" prop="type" v-if="sform.status == 3">
        <el-radio-group v-model="sform.type">
          <el-radio v-for="(v, k) in leaveType" :key="k" :label="k">{{
            v
          }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="原因：" v-if="sform.status == 3" prop="reason">
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入原因"
          v-model="sform.reason"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="confirm">确 定</el-button>
    </div>
  </el-dialog>
</template>
 
<script>
import { updateAttendance } from "@/http/analysis";
export default {
  data() {
    return {
      sform: {},
      types: this.$publicConfig.attendanceType,
      leaveType: this.$publicConfig.leaveType,
      rules: {
        status: [
          { required: true, message: "请选择考勤类型", trigger: "change" },
        ],
        type: [
          { required: true, message: "请选择请假类型", trigger: "change" },
        ],
        reason: [
          { required: true, message: "请输入原因", trigger: "change" },
        ]
      },
    };
  },
  props: {
    form: {
      type: Object,
      default() {
        return {};
      },
    },
    visible: {
      type: Boolean,
      default() {
        return false;
      },
    },
    recordType: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  mounted() {},
  created() {
    let form = JSON.parse(JSON.stringify(this.form))
    let sform = {}
    sform.type = form.leave ? form.leave.type : ''
    sform.status = form.status
    sform.reason = form.leave ? form.leave.reason : ''
    this.sform = sform;
  },
  methods: {
    close() {
      this.$emit("update:visible", false);
    },
    confirm() {
      this.sform.recordType = this.recordType;
      this.sform.recordId = this.form.id;
      this.$refs["form"].validate((valid) => {
        if (valid) {
          updateAttendance(this.sform).then((res) => {
            if (res.retCode == 1) {
              this.$message.success("更新成功");
              this.close();
            } else {
              this.$message.error(res.retMsg);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
  watch: {
  },
};
</script>

<style lang="scss" scoped>
</style>