<template>
  <basic-block :title="currentTerm.termName">
    <template #search>
      <el-date-picker v-model="date" :size="$publicConfig.selectSize" type="daterange" range-separator="至" start-placeholder="开始日期" :default-time="['00:00:00', '23:59:59']" value-format="yyyy.MM.dd" @change="
              (e) => {
                search.dateBegin = e[0];
                search.dateEnd = e[1];
                teacherRecordOne()
              }
            " end-placeholder="结束日期">
      </el-date-picker>
    </template>
    <div class="item_info">
      <div class="content">
        <div class="avater">
          <img :src="detail.avater || '/aaw/img/picture.png'" width="100%" alt="" srcset="" height="100%" />
        </div>
        <div class="info">
          <div class="name">{{ allDetail.teacher.teacherName }}</div>
          <div class="number">
            <span class="label">带班&学科：</span>
            <span>{{ allDetail.fullCourseNames.join(',') }}</span>
          </div>
          <div>
            <span class="label">应考勤次数：</span>
            <span>{{ tableData.length }}次</span>
            <span class="interval"></span>
            <span class="label">实考勤次数：</span>
            <span>{{ tableData.filter(item => item.status != 4).length  }}次</span>
            <span class="interval"></span>
            <span class="label">考勤率：</span>
            <span>{{ ((tableData.filter(item => item.status != 4).length) / tableData.length * 100).toFixed(2) }}%</span>
          </div>
        </div>
      </div>
    </div>
    <div class="content_table">
      <el-table :data="tableData" style="width: 100%" stripe>
        <el-table-column prop="time" label="考勤日期">
          <template scope="scope">
            {{ $cftY(scope.row.date).replaceAll('/', '-') }}
          </template>
        </el-table-column>
        <el-table-column prop="name" label="考勤节次">
          <template scope="scope">
            第{{ scope.row.periodId + 1 }}节课
          </template>
        </el-table-column>
        <el-table-column prop="fullCourseName" label="考勤课程"></el-table-column>
        <el-table-column prop="name" label="考勤时间">
          <template scope="scope">
            {{ $cftY(scope.row.time).replaceAll('/', '-') }}
          </template>
        </el-table-column>
        <el-table-column prop="name" label="考勤结果">
          <template scope="scope">
            <el-tag :style="`width: 40px;text-align: center;color:${$publicConfig.attendanceTypeColor[scope.row.status - 1]};border-color: ${$publicConfig.attendanceTypeColor[scope.row.status - 1]}`">{{ $publicConfig.attendanceType[scope.row.status - 1] }}</el-tag>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="name" label="考勤次数"></el-table-column> -->
        <el-table-column prop="name" label="请假状态">
          <template scope="scope">
            {{ scope.row.leave ? '已请假' : '无' }}
          </template>
        </el-table-column>
        <el-table-column prop="name" label="类型">
          <template scope="scope">
            {{ scope.row.leave ? $publicConfig.leaveType[scope.row.leave.type] : '无' }}
          </template>
        </el-table-column>
        <el-table-column prop="name" label="备注">
          <template scope="scope">
            {{ scope.row.leave ? scope.row.leave.reason : '无' }}
          </template>
        </el-table-column>
        <el-table-column prop="name" label="操作">
          <template slot-scope="scope">
            <el-link type="primary" v-if="scope.row.status == 1" disabled>修改</el-link>
            <el-link type="primary" v-else @click="() => {
              form = JSON.parse(JSON.stringify(scope.row))
              dialogFormVisible = true
              }">修改</el-link>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <update-status v-if="dialogFormVisible" :visible.sync="dialogFormVisible" :form.sync="form" ref="updateStatus"></update-status>
  </basic-block>
</template>

<script>
import UpdateStatus from "@/views/attendance/analysis/components/updateStatus";
import { teacherRecordOne } from "@/http/analysis";
import { mapGetters } from "vuex";
import { getSchoolTerm } from "@/http/leaveSchool";

export default {
  data() {
    return {
      form: {},
      search: {
        dateBegin: this.$cftY().split(" ")[0].replaceAll("/", "."),
        dateEnd: this.$cftY().split(" ")[0].replaceAll("/", "."),
      },
      date: [
        this.$cftY().split(" ")[0].replaceAll("/", "."),
        this.$cftY().split(" ")[0].replaceAll("/", "."),
      ],
      dialogFormVisible: false,
      detail: {},
      allDetail: { fullCourseNames: [] },
      tableData: [],
      currentTerm: {},
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  components: {
    UpdateStatus,
  },
  created() {
    let params = {
      schoolId: this.userInfo.tnId,
      termId: 0,
    };
    getSchoolTerm(params).then((res) => {
      this.currentTerm = res.retContent;
    });
    this.detail = this.$route.query;
    this.search.teacherId = this.$route.query.teacherId;
    console.log(this.detail);
    this.search.dateBegin = this.$route.query.dateBegin;
    this.search.dateEnd = this.$route.query.dateEnd;
    this.date = [this.search.dateBegin, this.search.dateEnd];
    this.teacherRecordOne();
  },
  methods: {
    teacherRecordOne() {
      this.search.schoolId = this.userInfo.tnId;
      teacherRecordOne(this.search).then((res) => {
        if (res.retCode == 1) {
          this.allDetail = res.data || { fullCourseNames: [] };
          console.log(res);
          this.tableData = res.data ? res.data.items : [];
        } else {
          this.$message.error(res.retMsg);
        }
      });
    },
  },
  watch: {
    dialogFormVisible(n) {
      if (n == false) {
        this.teacherRecordOne();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.item_info {
  width: 100%;
  height: 145px;
  background: #f7f8fa;
  border-radius: 2px;
  .content {
    display: flex;
    padding: 10px 47px;
    height: 100%;
    .avater {
      width: 114px;
      height: 126px;
      margin-right: 44px;
    }
    .info {
      font-size: 14px;
      color: #333333;
      span {
        display: inline-block;
      }
      .interval {
        width: 20px;
      }
      .name {
        font-size: 22px;
        font-weight: 500;
        color: #333333;
      }
      .number {
        margin: 18px 0 12px 0;
      }
      .label {
        color: #999999;
      }
    }
  }
}
.content_table {
  margin-top: $main-padding;
}
</style>